import { gql } from '@apollo/client';
import { Box, Checkbox, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useContext, useMemo } from 'react';
import {
  Column,
  Row,
  TableInstance,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { Columns } from '~components/displays/DisplayTable/constants';
import { useFilterOptions } from '~components/displays/DisplayTable/Filtering/useFilterOptions';
import {
  DisplaysQueryContext,
  useDisplaysQuery,
} from '~components/displays/DisplayTable/useDisplaysQuery';
import { ChevronDownIcon, ChevronUpIcon } from '~components/ui/icons';
import { defaultPageSizes as pageSizes } from '~components/ui/PageSizeSelector';
import { SelectOption } from '~components/ui/Select';
import { StopClickPropagation } from '~components/ui/StopClickPropagation';
import { useAnalyticsReporter } from '~utils/analytics';
import { compareMaybeStrings } from '~utils/compare';
import { MaybePromise } from '~utils/types';
import { useDisplayPresence } from '../useDisplayPresence';
import { useStatus } from '../useStatus';
import { DisplayTable_DisplayFragment } from './__generated__/DisplayTable.graphql';
import { AliasCell } from './Cells/AliasCell';
import { ContentSourceCell, extractSource } from './Cells/ContentSourceCell';
import { FirmwareCell } from './Cells/FirmwareCell';
import { GroupsCell } from './Cells/GroupsCell';
import { PlaylistCell } from './Cells/PlaylistCell';
import { PowerScheduleCell } from './Cells/PowerScheduleCell';
import { SiteCell } from './Cells/SiteCell';
import { StatusCell } from './Cells/StatusCell';
import { VolumeCell } from './Cells/VolumeCell';
import { WarningsCell } from './Cells/WarningsCell';
import {
  Filter,
  filterByFirmware,
  filterByGroups,
  filterByPlaylist,
  filterByPowerSchedule,
  filterBySite,
  filterRowByStatus,
  filterRowByWarningStatus,
} from './Filtering/Filter';
import { DisplayTablePageSizeSelector, DisplayTablePagination } from './Pagination';
import SearchBar from './SearchBar';

export function useDisplayTable(data: DisplayTable_DisplayFragment[]) {
  const { sortByPresenceAndPower } = useDisplayPresence();
  const { getStatus, sortByWarningSeverity } = useStatus();
  const { getPresence, getPowerState } = useDisplayPresence();
  const { search } = useContext(DisplaysQueryContext);

  const filteredData = useMemo(() => {
    const searchMatch = (...args: string[]) =>
      args.some((str) => str.toLowerCase().includes(search.toLowerCase()));
    return (
      (search
        ? data.filter((display) =>
            searchMatch(
              display.alias ?? '',
              display.networkInformation.ethernetMacAddress ?? '',
              display.networkInformation.wifiMacAddress ?? '',
              display.networkInformation.localIPAddress ?? '',
            ),
          )
        : data) || []
    );
  }, [data, search]);

  const columns = useMemo<Array<Column<DisplayTable_DisplayFragment>>>(
    () => [
      {
        id: Columns.Selection,
        Header: ({ getToggleAllPageRowsSelectedProps }) => {
          const { checked, indeterminate, onChange, role, title } =
            getToggleAllPageRowsSelectedProps();

          return (
            <Checkbox
              isChecked={checked}
              isIndeterminate={indeterminate}
              onChange={onChange}
              role={role}
              title={title}
            />
          );
        },
        Cell: ({ row }: { row: Row<DisplayTable_DisplayFragment> }) => {
          const { checked, indeterminate, onChange, role, title } = row.getToggleRowSelectedProps();

          return (
            <StopClickPropagation>
              <Checkbox
                isChecked={checked}
                isIndeterminate={indeterminate}
                onChange={onChange}
                role={role}
                title={title}
              />
            </StopClickPropagation>
          );
        },
        width: 'auto',
      },
      {
        id: Columns.Status,
        Header: 'Status',
        accessor: (d) => d,
        sortType: (rowA, rowB) => sortByPresenceAndPower(rowA.original, rowB.original),
        filter: (
          rows: Array<Row<DisplayTable_DisplayFragment>>,
          _: string[],
          filterValues: SelectOption[],
        ) =>
          rows.filter((row) => {
            const presence = getPresence(row.original);
            const powerState = getPowerState(row.original);

            return filterRowByStatus(row, presence, powerState, filterValues);
          }),
        Cell: StatusCell,
        width: 'auto',
      },
      {
        id: Columns.Alias,
        Header: 'Alias',
        accessor: (d) => d.alias,
        sortType: (rowA, rowB) => compareMaybeStrings(rowA.original.alias, rowB.original.alias),
        Cell: AliasCell,
        width: 'auto',
        minWidth: 180,
        maxWidth: 180,
      },
      {
        id: Columns.Site,
        accessor: (d) => d.site,
        Header: 'Site',
        sortType: (rowA, rowB) =>
          compareMaybeStrings(rowA.original.site?.name, rowB.original.site?.name),
        filter: filterBySite,
        Cell: SiteCell,
        width: '180px',
        minWidth: 180,
        maxWidth: 180,
      },
      {
        // Hidden
        id: Columns.Firmware,
        Header: 'Firmware',
        accessor: (d) => d.firmware,
        sortType: (rowA, rowB) =>
          compareMaybeStrings(
            rowA.original.firmware?.android.version,
            rowB.original.firmware?.android.version,
          ),
        filter: filterByFirmware,
        Cell: FirmwareCell,
        width: 'auto',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        // Hidden
        id: Columns.Playlist,
        Header: 'Playlist',
        accessor: (d) => d.playlist,
        sortType: (rowA, rowB) =>
          compareMaybeStrings(
            rowA.original.playlist?.current?.title,
            rowB.original.playlist?.current?.title,
          ),
        filter: filterByPlaylist,
        Cell: PlaylistCell,
        width: 'auto',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        // Hidden
        id: Columns.PowerSchedule,
        Header: 'Power Schedule',
        accessor: (d) => d.powerSchedule,
        sortType: (rowA, rowB) =>
          compareMaybeStrings(
            rowA.original.powerSchedule?.schedule?.title,
            rowB.original.powerSchedule?.schedule?.title,
          ),
        filter: filterByPowerSchedule,
        Cell: PowerScheduleCell,
        width: 'auto',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        id: Columns.Groups,
        Header: 'Groups',
        accessor: (d) => d.groups,
        disableSortBy: true,
        filter: filterByGroups,
        Cell: GroupsCell,
        width: '180px',
        maxWidth: 180,
        minWidth: 180,
      },
      {
        id: Columns.Warnings,
        Header: 'Warnings',
        accessor: (d) => d,
        sortType: (rowA, rowB) => sortByWarningSeverity(rowA.original, rowB.original),
        // Since we need to filter on `status`, and getting that status relies on hooks, mapping the rows is lifted into
        // this file, and the filter itself handles only single rows
        filter: (
          rows: Array<Row<DisplayTable_DisplayFragment>>,
          _: string[],
          filterValues: SelectOption[],
        ) =>
          rows.filter((row) => {
            const status = getStatus(row.original);
            return filterRowByWarningStatus(row, status, filterValues);
          }),
        Cell: WarningsCell,
        width: '100%',
        minWidth: 279, // yep, no toucherino.
      },
      {
        id: Columns.ContentSource,
        Header: 'Active Source',
        accessor: (d) => d.contentSource,
        sortType: (rowA, rowB) =>
          compareMaybeStrings(
            extractSource(rowA.original.contentSource?.current?.desired) ??
              extractSource(rowA.original.contentSource?.current?.reported),
            extractSource(rowB.original.contentSource?.current?.desired) ??
              extractSource(rowB.original.contentSource?.current?.reported),
          ),
        Cell: ContentSourceCell,
        width: '150px',
        maxWidth: 150,
        minWidth: 150,
      },
      {
        id: Columns.Volume,
        Header: 'Volume',
        accessor: (d) => d.volume,
        disableSortBy: true,
        Cell: VolumeCell,
        width: 'auto',
        minWidth: 90,
        maxWidth: 90,
      },
    ],
    [sortByPresenceAndPower, getPresence, getPowerState, sortByWarningSeverity, getStatus],
  );

  const filterOptions = useFilterOptions([], data);
  const { persistFilters, persistPageSize, persistedDisplaysState, persistSorting } =
    useDisplaysQuery();

  return useTable(
    {
      columns,
      data: filteredData,
      initialState: {
        pageSize: pageSizes[0],
        filters: [
          { id: Columns.Firmware, value: [] },
          { id: Columns.Groups, value: [] },
          { id: Columns.Playlist, value: [] },
          { id: Columns.PowerSchedule, value: [] },
          { id: Columns.Site, value: [] },
          { id: Columns.Warnings, value: [] },
          { id: Columns.Status, value: [] },
        ],
        hiddenColumns: [Columns.Firmware, Columns.Playlist, Columns.PowerSchedule],
        ...persistedDisplaysState?.(filterOptions),
      },
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
      stateReducer: (newState, action) => {
        switch (action.type) {
          case 'setFilter':
          case 'setAllFilters':
            persistFilters(newState.filters);
            break;
          case 'setPageSize':
            persistPageSize(newState.pageSize);
            break;
          case 'toggleSortBy':
            persistSorting(newState.sortBy);
            break;
        }
        return newState;
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  );
}

/* eslint-disable react/jsx-key */
export function DisplayTable({
  table,
  onGoToDetail,
  invalidDisplayIds,
}: {
  table: TableInstance<DisplayTable_DisplayFragment>;
  onGoToDetail: (id: string) => MaybePromise<void>;
  invalidDisplayIds?: string[];
}) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = table;
  const analytics = useAnalyticsReporter();

  return (
    <Table {...getTableProps()} variant="simple">
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const headerProps = column.getHeaderProps(column.getSortByToggleProps());
              return (
                <Th
                  {...headerProps}
                  color={column.isSorted ? 'gray.700' : 'gray.600'}
                  minWidth={`${column.minWidth}px`}
                  maxWidth={`${column.maxWidth}px`}
                  width={column.width}
                  _hover={
                    column.disableSortBy
                      ? {}
                      : {
                          textColor: 'gray.800',
                        }
                  }
                  onClick={(e) => {
                    if (column.id !== 'selection') {
                      analytics.track('displaySort', { sortType: column.id });
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (headerProps as any).onClick?.(e);
                  }}
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box flex="1">{column.render('Header')}</Box>
                    {column.canSort && (
                      <Box>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ChevronDownIcon display="inherit" />
                          ) : (
                            <ChevronUpIcon display="inherit" />
                          )
                        ) : (
                          <ChevronDownIcon visibility="hidden" />
                        )}
                      </Box>
                    )}
                  </Box>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);

          const hasServerErrors = invalidDisplayIds?.includes(row.original.id);
          const hasEmptyShadow = row.original.hasEmptyShadow;

          return (
            <Tr
              {...row.getRowProps()}
              {...(hasServerErrors
                ? {
                    background: 'gray.50',
                  }
                : {
                    role: 'button',
                    background: hasEmptyShadow ? 'red.25' : 'inherit',
                    _hover: {
                      background: hasEmptyShadow ? 'red.50' : 'blue.50',
                      cursor: 'pointer',
                    },
                  })}
            >
              {row.cells.map((cell) => {
                return (
                  <Td
                    minWidth={`${cell.column.minWidth}px`}
                    maxWidth={`${cell.column.maxWidth}px`}
                    width={cell.column.width}
                    onClick={
                      hasServerErrors
                        ? undefined
                        : cell.column.id === Columns.Selection
                        ? () => cell.row.toggleRowSelected()
                        : () => {
                            analytics.track('displayDetail');
                            onGoToDetail(row.original.id);
                          }
                    }
                    {...(hasServerErrors ? { opacity: 0.5 } : {})}
                    {...cell.getCellProps()}
                  >
                    {cell.column.id === Columns.Selection && hasServerErrors
                      ? null
                      : cell.render('Cell')}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
/* eslint-enable react/jsx-key */

DisplayTable.Filter = Filter;
DisplayTable.Pagination = DisplayTablePagination;
DisplayTable.PageSizeSelector = DisplayTablePageSizeSelector;
DisplayTable.SearchSelector = SearchBar;

DisplayTable.graphql = {
  fragments: {
    DisplayTable_display: gql`
      fragment DisplayTable_display on Display {
        id
        commercialTypeNumber
        networkInformation {
          ethernetMacAddress
          localIPAddress
          wifiMacAddress
        }
        hasEmptyShadow
        ...UseDisplayPresence_display
        ...UseRecommendedSettings_display
        ...AliasCell_display
        ...CTNCell_display
        ...GroupsCell_display
        ...ContentSourceCell_display
        ...SiteCell_display
        ...StatusCell_display
        ...FirmwareCell_display
        ...PlaylistCell_display
        ...PowerScheduleCell_display
        ...VolumeCell_display
        ...BookmarksCell_display
        ...WarningsCell_display
        ...PlatformCell_display
      }
    `,
  },
};
