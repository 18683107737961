import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import React, { useContext, useEffect, useRef } from 'react';
import { SearchIcon } from '~components/ui/icons';
import { DisplaysQueryContext } from './useDisplaysQuery';

const SearchBar = () => {
  const { search, setSearch } = useContext(DisplaysQueryContext);
  // Debounced search function to update the URL
  const debouncedSearch = useRef(
    debounce((newSearch: string) => {
      const currentParams = new URLSearchParams(window.location.search);
      if (newSearch) {
        currentParams.set('search', newSearch); // Set or update the 'search' parameter
      } else {
        currentParams.delete('search'); // Remove the 'search' parameter if empty
      }
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      history.replaceState({}, window.document.title, newUrl);
      setSearch?.(newSearch);
    }, 100),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Handle search input changes
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  return (
    <InputGroup width="100%">
      <InputLeftElement pointerEvents="none">
        <SearchIcon bgColor="white" color="gray.500" />
      </InputLeftElement>
      <Input
        type="text"
        placeholder="Type to search..."
        onChange={handleSearch}
        defaultValue={search}
        variant="outline"
        size="md"
      />
    </InputGroup>
  );
};

export default SearchBar;
