import { gql } from '@apollo/client';
import { Grid, GridItem, Heading } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { isNil } from 'lodash';
import { useAuth } from '~auth/useAuth';
import { useDisplayDetailSettingsTab } from '~components/displays/DisplayDetail/DisplayDetailView';
import { DisplayGeneralSection } from '~components/displays/DisplayDetail/general/DisplayGeneralSection';
import { DisplayInfoSection } from '~components/displays/DisplayDetail/info/DisplayInfoSection';
import { DisplayTimeSection } from '~components/displays/DisplayDetail/time/DisplayTimeSection';
import {
  DateTimeIcon,
  ErrorIcon,
  InfoIcon,
  LandscapeIcon,
  LockIcon,
  PowerIcon,
  VolumeHighIcon,
} from '~components/ui/icons';
import { ConnectivityIcon } from '~components/ui/icons/ConnectivityIcon';
import { useTabs } from '~components/ui/Tabs';
import {
  ActiveTabBackground,
  VerticalTab,
  VerticalTabList,
  VerticalTabPanel,
  VerticalTabs,
} from '~components/ui/VerticalTabs';
import { isDefined } from '~utils/types';
import { ConnectivitySection } from './connectivity/ConnectivitySection';
import { DangerZoneSection } from './dangerzone/DangerZoneSection';
import { DisplayLockingSection } from './locking/DisplayLockingSection';
import { PlaybackSection } from './playback/PlaybackSection';
import { PowerSettingsSection } from './power/PowerSettingsSection';

export function DisplayDetailSettings() {
  const { customer, display, refetchData, scrollOffset } = useDisplayDetailSettingsTab();
  const { verifyUserPermissions } = useAuth();

  const tabDefinitions = [
    {
      label: 'General',
      icon: LandscapeIcon,
      element: (
        <DisplayGeneralSection customer={customer} display={display} refetchData={refetchData} />
      ),
      path: '#general',
    },
    {
      label: 'Date and time',
      icon: DateTimeIcon,
      element: <DisplayTimeSection display={display} />,
      path: '#datetime',
    },
    {
      label: 'Playback',
      icon: VolumeHighIcon,
      element: <PlaybackSection display={display} />,
      path: '#playback',
    },
    isNil(display.signalDetection?.reported)
      ? null
      : {
          label: 'Power',
          icon: PowerIcon,
          element: <PowerSettingsSection display={display} />,
          path: '#power',
        },
    {
      label: 'Locking',
      icon: LockIcon,
      element: <DisplayLockingSection display={display} />,
      path: '#locking',
    },
    {
      label: 'Display information',
      icon: InfoIcon,
      element: <DisplayInfoSection display={display} />,
      path: '#displayinfo',
    },
    {
      label: 'Connectivity',
      icon: ConnectivityIcon,
      element: <ConnectivitySection display={display} />,
      path: '#connectivity',
    },
    verifyUserPermissions([
      Permission.DisplayUnclaim,
      Permission.DisplayFirmwareUpdate,
      Permission.DisplaySettingsUpdate,
    ])
      ? {
          label: 'Danger zone',
          icon: ErrorIcon,
          element: <DangerZoneSection display={display} />,
          path: '#dangerzone',
          color: 'red.400',
          borderColor: 'red.100',
        }
      : null,
  ].filter(isDefined);

  // While the `useTabs` hook is used here, there is not matching `<Outlet />` since the paths are hashes, and not
  // nested routes. Rendering the tabs is done manually in this scenario.
  const { tabs } = useTabs(tabDefinitions, { animateVertical: true, scrollOffset });

  return (
    <>
      <VerticalTabs spacing={{ base: 0, lg: 10 }}>
        <VerticalTabList
          stickyOffset={scrollOffset}
          display={{ base: 'none', lg: 'block' }}
          style={{ marginRight: '110px' }}
        >
          {tabs.map((tab, i) => (
            <VerticalTab key={tab.path} ref={tab.ref} tab={tab} prevTab={tabs[i - 1]} />
          ))}
          <ActiveTabBackground tabs={tabs} />
        </VerticalTabList>
        <VerticalTabPanel>
          {tabs.map(({ icon: Icon, ...tab }) => (
            <Grid
              key={tab.path}
              templateColumns="repeat(8, 1fr)"
              gap="0 5%"
              mt="120"
              _first={{ mt: 0 }}
              ref={tab.contentRef}
            >
              <GridItem colSpan={7}>
                <Heading
                  id={tab.path?.replace('#', '')}
                  borderBottom="1px solid"
                  borderColor={tab.borderColor || 'gray.50'}
                  fontSize="1.5rem"
                  mb="8"
                  pb="2"
                  display="flex"
                  alignItems="center"
                  color={tab.color}
                >
                  {Icon !== undefined && <Icon width="6" height="6" mr="3" />}
                  {tab.label}
                </Heading>
              </GridItem>
              {tab.element}
            </Grid>
          ))}
        </VerticalTabPanel>
      </VerticalTabs>
    </>
  );
}

DisplayDetailSettings.graphql = {
  fragments: {
    DisplayDetailSettings_customer: gql`
      fragment DisplayDetailSettings_customer on Customer {
        id
        ...DisplayGeneralSection_customer
      }
    `,
    DisplayDetailSettings_display: gql`
      fragment DisplayDetailSettings_display on Display {
        id
        ...DisplayInfoSection_display
        ...PlaybackSection_display
        ...PowerSettingsSection_display
        ...DisplayLockingSection_display
        ...DisplayAutoRestart_display
        ...ConnectivitySection_display
        ...DangerZoneSection_display
        ...DisplayTimeSection_display
        ...DisplayGeneralSection_display
      }
    `,
  },
};
